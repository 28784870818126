import React from "react";
import { graphql, Link } from "gatsby";

import TEDxKIContainer from '../components/tedxki-container'

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as styles from './speakers.module.css'

export default function SpeakersPage({ data }) {

  const contentfulTEDxKI = data.allContentfulTeDxKi.edges[0].node;
  const groupedAndSortedSpeakers = [contentfulTEDxKI.upcomingEvent, ...contentfulTEDxKI.pastEvents]
  .filter((e) => e.speakers)
  .map((e) => {
    return {
      yearIdentifier: e.yearIdentifier,
      sortedSpeakers: e.speakers.sort((s1, s2) => {
        if (s1.sortValue > s2.sortValue) {
          return 1;
        } else if (s1.sortValue < s2.sortValue) {
          return -1;
        } else {
          return 0;
        }
      })
    }
  });

  return (
    <TEDxKIContainer>
      <div>
        <h3>
          <center>Find out more about our incredible growing speaker community!</center>
        </h3>
        {groupedAndSortedSpeakers.map(speakersGrouped => {
          return (
            <div>
              <h3 class="text-center mt-3">{speakersGrouped.yearIdentifier}</h3>
              <Container>
                <Row lg={4} xs={1} className="justify-content-center">
                  {speakersGrouped.sortedSpeakers.map(speaker => {
                    return (
                      <Col className="mb-3">
                        <Card key={speaker.id}>
                          <Card.Img variant="top"
                            src={speaker.photo.file.url}
                            alt={speaker.name}
                            className={styles.speaker_photo}
                          />
                          <Card.Body>
                            <Card.Title className="text-center">{speaker.name}</Card.Title>
                            <a href={speaker.linkedInProfileLink} class="stretched-link"></a>
                          </Card.Body>
                        </Card>
                      </Col>
                    )
                  })}
                </Row>
              </Container>
            </div>
            )
        })}
        <div className={styles.stay_tuned}>
          <h4>
            <Link to="/events/">Stay tuned for more inspiration from 2024 event soon...</Link>
          </h4>
        </div>
      </div>
    </TEDxKIContainer>
  );
};

export const query = graphql`
  query {
    allContentfulTeDxKi {
      edges {
        node {
          upcomingEvent {
            yearIdentifier
            speakers {
              photo {
                file {
                  url
                }
              }
              jobTitle
              name
              bio {
                childMarkdownRemark {
                  internal {
                    content
                  }
                }
              }
              linkedInProfileLink
              sortValue
            }
          }
          pastEvents {
            yearIdentifier
            speakers {
              photo {
                file {
                  url
                }
              }
              jobTitle
              name
              bio {
                childMarkdownRemark {
                  internal {
                    content
                  }
                }
              }
              linkedInProfileLink
              sortValue
            }
          }
        }
      }
    }
  }
`;
